.card-plan {
  max-width: 425px;
  min-height: 525px;
  margin: auto;
  border-color: #b9eaf5 !important;
}

.card-plan-header {
  color: #fff !important;
  background-color: #392eb8 !important;
  font-weight: bold;
  text-align: center;
}

.card-plan-sticky {
  margin-top: 10px;
  color: #fff;
  background-color: #392eb8;
  text-align: center;
}

.advantage-text {
  margin-left: 10px;
}

.advantage-icon {
  color: #b9eaf5;
}

.advantage-item {
  border-color: #b9eaf5 !important;
}

.advantage-item-disabled {
  opacity: 0.5;
}

.advantage-text-disabled {
  color: #b9eaf5;
}
