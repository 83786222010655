.specialty-card {
  border-radius: 20px !important;
  border-color: #fff !important;
  box-shadow: 0px 0px 18px rgb(8 5 24 / 12%);
  margin-bottom: 25px;
  cursor: pointer;
}

.specialty-card-body {
  text-align: center;
}
