.question-help-header {
  color: #fff !important;
  background-color: #392eb8 !important;
  font-weight: bold;
  text-align: center;
}

.question-help-body {
  min-height: 100px;
}
