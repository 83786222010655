#features {
  background-color: #e0f8fe;
}

.features-col-first-large-padding {
  padding: 100px 0px;
}

.features-col-first-small-padding {
  padding: 30px 0px;
}

.features-col-second {
  padding: 20px 0px;
}

.features-title {
  color: #000 !important;
}

.img-features {
  position: absolute;
  top: 100px;
  max-height: 400px;
}

.features-col-second {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-download-app {
  width: 200px !important;
  height: 60px !important;
  cursor: pointer;
}

.features-card-section {
  position: absolute;
  margin-top: -100px !important;
}

.features-card {
  position: relative !important;
  top: 20px;
  border-radius: 20px !important;
  border-color: #fff !important;
  box-shadow: 0px 0px 18px rgb(8 5 24 / 12%);
}

.features-card-item {
  padding: 40px;
}

.features-card-item-title {
  font-size: 18px;
  font-weight: bold;
  color: #392eb8;
}

.features-card-item-icon {
  color: #51d3e4;
  margin-left: 20px;
  width: 20px;
  height: 20px;
}
