.answer-div {
  min-height: 100px;
}

.card-reverse {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flip-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
