.suspend-card-header {
  color: #fff !important;
  background-color: #392eb8 !important;
  font-weight: bold;
  text-align: center;
}

.suspend-card-body {
  text-align: justify;
}

.suspend-card-modal-buttons {
  margin-top: 20px;
  text-align: center;
}
