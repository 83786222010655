.search-card-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.search-card-item:hover {
  background-color: #392eb8;
  color: #fff;
}

.search-card-text {
  margin-left: 10px;
}
