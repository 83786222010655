.question-div {
  min-height: 100px;
}

.card-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.suspend-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #8c2922;
}
