.progress-item {
  padding: 10px;
  cursor: pointer;
}

.free-cards-indicator {
  margin-left: 15px;
}

.percentage-indicator {
  text-align: center;
}
