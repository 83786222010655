#benefits {
  padding: 50px 0px;
  background-color: #392eb8;
}

.benefits-col {
  color: #fff;
  padding: 0 5% !important;
}

.benefits-title {
  margin-top: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #fff !important;
}

@media (max-width: 768px) {
  .img-benefits {
    margin-top: 30px;
  }
}
