#profile {
  padding: 50px 0px;
}

.accordion-header-profile-title {
  margin-left: 10px;
}

.fai-profile-accordion {
  width: 22.5px !important;
}
