@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: local("Ubuntu"), local("Ubuntu-Regular"),
    url("/src/assets/fonts/Ubuntu.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accordion-button:not(.collapsed) {
  color: #392eb8 !important;
  font-weight: bold;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #d9e8ff !important;
}

.accordion-button:focus {
  border-color: #0d1c33 !important;
  box-shadow: #d9e8ff !important;
}

.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.active > .page-link,
.page-link.active {
  color: #fff !important;
  background-color: #392eb8 !important;
  border-color: #392eb8 !important;
}

.page-link {
  color: #392eb8 !important;
}
