.m-r-20 {
  margin-right: 20px;
}

.fw-600 {
  font-weight: 600;
}

h2 {
  color: #392eb8 !important;
  font-weight: bold !important;
}

h3 {
  color: #392eb8 !important;
  font-weight: 700 !important;
}

h5 {
  color: #392eb8 !important;
  font-weight: 700 !important;
}

.bg-main {
  --bs-bg-opacity: 1;
  background-color: #392eb8 !important;
}

a.nav-link {
  color: #fff !important;
}

.navbar-toggler {
  border-color: #fff !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.btn-main {
  background-color: #392eb8 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn-second {
  background-color: #51d3e4 !important;
  color: #000 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn-danger {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.section-title {
  padding-bottom: 30px;
}

.section-description {
  font-size: 18px;
}

.no-padding {
  padding: 0px !important;
}

.option-link {
  color: #392eb8 !important;
}

.form-group {
  margin-bottom: 10px;
}

.main-color {
  color: #392eb8 !important;
}

.btn-easy {
  width: 100%;
  background-color: #228c4e !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn-medium {
  width: 100%;
  background-color: #8c7a22 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn-hard {
  width: 100%;
  background-color: #8c2922 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.pointer {
  cursor: pointer;
}

.text-main {
  --bs-text-opacity: 1;
  color: #392eb8 !important;
}

.progress {
  margin-top: 5px;
}

.no-margin {
  margin: 0px !important;
}

.m-l-5 {
  margin-left: 5px;
}

.p-t-20 {
  padding-top: 20px;
}

.main-sticky {
  background-color: #392eb8;
}

.hidden {
  visibility: hidden !important;
}

.form-check-input:checked {
  background-color: #392eb8 !important;
  border-color: #392eb8 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #392eb8 !important;
}

.element-sticky {
  color: #fff;
  background-color: #392eb8;
  text-align: center;
}

#premium-card-switch {
  height: 37.6px;
  min-width: 80px;
  margin-top: 0px !important;
  cursor: pointer;
}

.display-block {
  display: block !important;
}

.search-bar-group {
  align-items: center;
  background-color: white;
  position: relative;
  display: flex;
  border-radius: 0.25rem;
  border: 2px solid #392eb8;
}

.search-icon {
  width: 17px;
  height: 17px;
  margin-left: 20px;
  color: #392eb8;
}

.search-input {
  border: none !important;
  font-size: 18px;
}

.search-input::placeholder {
  color: #0064b5;
  opacity: 0.5;
}

.search-input:focus {
  border-color: white !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0) !important;
}

.h-60 {
  height: 60px;
}
